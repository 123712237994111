import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Divider from "@material-ui/core/Divider";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { makeStyles } from "@material-ui/core/styles";

import Oakland from "./images/oakland.jpg";
import Emeryville from "./images/emeryville.png";
import Hayward from "./images/hayward.jpg";

import Languages from "./languages";

import { AssignmentLateSharp } from "@material-ui/icons";

const useStyles = makeStyles({
	option: {
		fontSize: 15,
		"& > span": {
			marginRight: 10,
			fontSize: 18,
		},
	},
});

// import atlas from 'azure-maps-rest'
// // Get an Azure Maps key at https://azure.com/maps.
// var subscriptionKey = 'FKNIAeFQ1u2emlgfmjchT8CLrBLesFNwhQq3muDkP_Y';

// // Use SubscriptionKeyCredential with a subscription key.
// var subscriptionKeyCredential = new atlas.SubscriptionKeyCredential(subscriptionKey);

// // Use subscriptionKeyCredential to create a pipeline.
// var pipeline = atlas.MapsURL.newPipeline(subscriptionKeyCredential, {
//   retryOptions: { maxTries: 4 } // Retry options
// });

// // Create an instance of the SearchURL client.
// var searchURL = new atlas.SearchURL(pipeline);

const raceChoices = [
	"American Indian or Alaska Native",
	"Asian",
	"Black or African American",
	"Native Hawaiian or Other Pacific Islander",
	"White",
	"Other Multi-Racial",
	"I’d prefer not to say",
];

const ethnicityChoices = ["Hispanic or Latinx", "Non-Hispanic or Latinx", "I’d prefer not to say"];

const genderIdentityChoices = [
	"Male/Man",
	"Female/Woman",
	"Trans Male/Trans Man",
	"Trans Female/Trans Woman",
	"Gender queer/Gender Non-conforming",
	"I’d prefer not to say",
];

const preferredPronounChoices = [
	"She/her",
	"He/him",
	"They/them",
	"Ze/hir",
	"I'd prefer not to say",
];

const sexualOrientationChoices = [
	"Bisexual",
	"Gay/Lesbian/Same-Gender Loving​",
	"Questioning/Unsure",
	"Straight/Heterosexual",
	"Not listed",
	"I'd prefer not to say",
];

const formatUSNumber = (entry = "") => {
	if (!entry || entry.length < 1) return entry;
	const match = entry
		.replace(/\D+/g, "")
		.replace(/^1/, "")
		.match(/([^\d]*\d[^\d]*){1,10}$/)[0];
	const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match;
	const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : "";
	const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : "";
	return `${part1}${part2}${part3}`;
};

export default function AddressForm(data) {
	const classes = useStyles();
	const findAddress = async (input) => {
		const subscriptionKey = "FKNIAeFQ1u2emlgfmjchT8CLrBLesFNwhQq3muDkP_Y";

		fetch(
			`https://atlas.microsoft.com/search/address/json?subscription-key=FKNIAeFQ1u2emlgfmjchT8CLrBLesFNwhQq3muDkP_Y&countrySet=US&api-version=1.0&query=${input}`
		)
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				console.log("res", res);
				const sA = res.results && res.results.length >= 1 ? res.results[0].address : null;

				const caResults = res.results.filter(
					(r) => r.address.countryCode == "US" && r.address.countrySubdivision == "CA"
				);
				console.log("caResults", caResults);

				if (caResults.length > 0) {
					console.log("suggested address", sA, res);
					// const sug = {...sA};
					//need to complete map to return only fields needed, street address, city and zip
					data.setSuggestedAddress({
						...data.suggestedAddress,
						address: caResults.map((r) => {
							return {
								value: `${r.address.streetNumber} ${r.address.streetName}`,
								inputValue: `${r.address.streetNumber} ${r.address.streetName}`,
								country: r.address.country,
								countryCode: r.address.countryCode,
								countrySecondarySubdivision: r.address.countrySecondarySubdivision,
								countrySubdivision: r.address.countrySubdivision,
								freeformAddress: r.address.freeformAddress,
								localName: r.address.localName,
								municipality: r.address.municipality,
								postalCode: r.address.postalCode,
								streetName: r.address.streetName,
								streetNumber: r.address.streetNumber,
							};
						}),
					});
				} else {
					console.log(res);
				}
				// updateData(data.suggestedAddress, sA);
			})
			.catch((err) => console.log("err", err));
	};

	console.log("sA", data);

	const {
		howDidYouHear,
		dueToCovid,
		whatCausedCrisis,
		crisisOther,
		race,
		firstName,
		lastName,
		address1,
		address2,
		city,
		zip,
		dob,
		email,
		phone,
		whatCity,
		howManyInHousehold,
		howManyChildrenInHousehold,
		howManyChildrenInHouseholdUnder2,
		howMany18to24InHousehold,
		howMany25to54InHousehold,
		anyoneInHouseholdVeteran,
		typeOfHousing,
		employed,
		employment,
		howManySeniors,
		backRentMonths,
		backRentAmount,
		landlordPhone,
		landlordaddress1,
		landlordaddress2,
		landlordcity,
		landlordstate,
		landlordzip,
		error,
		llName,
		landlordEmail,
		preferredPronoun,
		genderIdentity,
		headOfHousehold25,
		ethnicity,
		needInterpretation,
		needInterpretationLang,
		liveInRentControlledUnit,
		livedFor5YearsMore,
		sexualOrientation,
		currentMonthlyRent,
		unpaidUtilities,
		backUtilityAmount,
		backUtilityMonths,
		previousAssistance,
		previousAssistanceAmount,
		backRentOwed,
		occupation,
		headOfHousehold62,
	} = data.formData;

	const { suggestedAddress, setSuggestedAddress } = data;

	const { selectedAddress, setSelectedAddress } = data;

	const updateData = (field, value) => {
		data.setFormData({ ...data.formData, [field]: value });
	};

	const imgStyle = {
		height: 65,
		// maxWidth: "80%",
		objectFit: "cover",
		display: "block",
		margin: "0 auto",
		marginTop: 10,
		marginBottom: 10,
	};
	const cityImage = () => {
		switch (whatCity) {
			case "Emeryville":
				return Emeryville;
			case "Hayward":
				return Hayward;
			case "Oakland":
				return Oakland;
			default:
				return "";
		}
	};

	// console.log("formData", data.formData);

	const checkError = (input) => {
		console.log("input", input, input.currentTarget);
	};
	const { errorFields, setErrorFields } = data;

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
				Applicant Information
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!howDidYouHear || howDidYouHear == "")}
					>
						<InputLabel shrink htmlFor="how-did-you-hear-about-us">
							How did you hear about us?
						</InputLabel>
						<InputLabel htmlFor="how-did-you-hear-about-us-input">
							How did you hear about us?
						</InputLabel>
						<Select
							native
							value={howDidYouHear}
							onChange={(evt) => updateData("howDidYouHear", evt.target.value)}
							inputProps={{
								name: "howDidYouHear",
								id: "how-did-you-hear-about-us-input",
							}}
						>
							<option value={""}></option>
							<option value={"Friends/Family"}>Friends/Family</option>
							<option value={"Community-based organization"}>Community-based organization</option>
							<option value={"Landlord"}>Landlord</option>
							<option value={"Other"}>Other</option>
							<option value={"211"}>211</option>
						</Select>
					</FormControl>
				</Grid>
				{/* <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal"}
            required
            error={error && (!dueToCovid || dueToCovid == "")}
          >
            <InputLabel shrink htmlFor="dueToCovid">
              Is your housing crisis due to COVID-19?
            </InputLabel>
            <InputLabel htmlFor="dueToCovid">
              Is your housing crisis due to COVID-19?
            </InputLabel>
            <Select
              native
              value={dueToCovid}
              onChange={(evt) => {
                updateData("dueToCovid", evt.target.value);
              }}
              inputProps={{
                name: "dueToCovid",
                id: "dueToCovid",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid> */}
				<Grid item xs={12} sm={6}>
					{dueToCovid && dueToCovid == "Yes" ? (
						<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
							<InputLabel shrink htmlFor="whatCausedCrisis">
								What caused your housing crisis
							</InputLabel>
							<InputLabel htmlFor="whatCausedCrisis">What caused your housing crisis?</InputLabel>
							<Select
								value={whatCausedCrisis}
								native
								onChange={(evt) => updateData("whatCausedCrisis", evt.target.value)}
								inputProps={{
									name: "whatCausedCrisis",
									id: "whatCausedCrisis",
								}}
							>
								<option aria-label="None" value="" />
								<option value={`Loss of job or reduction in hours`}>
									Loss of job or reduction in hours
								</option>
								<option value={`Loss of Wage Earner in House`}>Loss of Wage Earner in House</option>
								<option value={`COVID-19`}>COVID-19</option>
								<option value={`Other Medical Emergency`}>Other Medical Emergency</option>
								<option value={"Other"}>Other</option>
							</Select>
						</FormControl>
					) : (
						<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
							<InputLabel shrink htmlFor="whatCausedCrisis">
								What caused your housing crisis?
							</InputLabel>
							<InputLabel htmlFor="whatCausedCrisis">What caused your housing crisis?</InputLabel>
							<Select
								native
								value={whatCausedCrisis}
								onChange={(evt) => updateData("whatCausedCrisis", evt.target.value)}
								inputProps={{
									name: "whatCausedCrisis",
									id: "whatCausedCrisis",
								}}
							>
								<option aria-label="None" value="" />
								<option aria-label="Rental Theft" value="Rental Theft">
									Rental Theft
								</option>
								<option value={`Loss of Job or reduction in hours`}>
									Loss of Job or reduction in hours
								</option>
								<option value={`Loss of Wage Earner in House`}>Loss of Wage Earner in House</option>
								><option value={"COVID-19"}>COVID-19</option>
								<option value={"Other Medical Emergency"}>Other Medical Emergency</option>
								<option value={"Other"}>Other</option>
							</Select>
						</FormControl>
					)}
				</Grid>
				{whatCausedCrisis && whatCausedCrisis == "Other" ? (
					<Grid item xs={12} sm={12}>
						<TextField
							required
							id="crisisOther"
							name="crisisOther"
							label="Please describe in a few words"
							fullWidth
							defaultValue={crisisOther}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</Grid>
				) : null}

				<Grid item xs={12} sm={6}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!previousAssistance || previousAssistance == "")}
					>
						<InputLabel shrink htmlFor="previousAssistance">
							Have you previously received assistance?
						</InputLabel>
						<InputLabel htmlFor="previousAssistance">
							Have you previously received assistance?
						</InputLabel>
						<Select
							native
							value={previousAssistance}
							onChange={(evt) => {
								updateData("previousAssistance", evt.target.value);
							}}
							inputProps={{
								name: "previousAssistance",
								id: "previousAssistance",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{previousAssistance && previousAssistance == "Yes" ? (
					<Grid item xs={12}>
						<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
							{previousAssistanceAmount && previousAssistanceAmount != "" ? null : (
								<InputLabel style={{ paddingLeft: 15 }} htmlFor="previousAssistanceAmount">
									What was the amount of previous assistance you recieved?
								</InputLabel>
							)}
							<TextField
								// disabled={!toaBackRent}
								id="previousAssistanceAmount"
								name="previousAssistanceAmount"
								label={"What was the amount of previous assistance you recieved?"}
								fullWidth
								type={"number"}
								value={previousAssistanceAmount ? previousAssistanceAmount : ""}
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								onChange={(e) => updateData(e.target.name, e.target.value)}
							/>
						</FormControl>
					</Grid>
				) : null}

				<Grid item xs={12} sm={6}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!firstName || firstName == "")}
					>
						<InputLabel shrink htmlFor="firstName">
							First Name
						</InputLabel>
						<TextField
							required
							error={error && (!firstName || firstName == "")}
							id="firstName"
							name="firstName"
							label="First Name"
							fullWidth
							autoComplete="fname"
							defaultValue={firstName}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!lastName || lastName == "")}
					>
						<InputLabel shrink htmlFor="lastName">
							Last Name
						</InputLabel>
						<TextField
							required
							error={error && (!lastName || lastName == "")}
							id="lastName"
							name="lastName"
							label="Last Name"
							fullWidth
							autoComplete="lname"
							defaultValue={lastName}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="dob"
							label="Date of Birth (MM/DD/YYYY)"
							invalidDateMessage={
								<span style={{ color: "red" }}>Invalid Date - Please enter as MM/DD/YYYY</span>
							}
							fullWidth
							name={"dob"}
							value={dob}
							required
							error={error && (!dob || dob.indexOf("_") >= 0 || dob == "")}
							// onChange={handleDateChange}
							// defaultValue={dob}
							onChange={(date, value) => {
								console.log("value", value);
								updateData("dob", value);
							}}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
						/>
					</MuiPickersUtilsProvider>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!sexualOrientation || sexualOrientation == "")}
					>
						<InputLabel shrink htmlFor="sexualOrientation">
							Sexual Orientation
						</InputLabel>
						<InputLabel htmlFor="sexualOrientation">Sexual Orientation</InputLabel>
						<Select
							native
							value={sexualOrientation}
							// onChange={handleChange}
							name={"sexualOrientation"}
							onChange={(e) => updateData(e.target.name, e.target.value)}
							inputProps={{
								name: "sexualOrientation",
								id: "sexualOrientation",
							}}
						>
							<option aria-label="None" value="" />
							{sexualOrientationChoices.map((r) => (
								<option value={r} key={`control-${r}`}>
									{r}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!genderIdentity || genderIdentity == "")}
					>
						<InputLabel shrink htmlFor="genderIdentity">
							Gender Identity
						</InputLabel>
						<InputLabel htmlFor="genderIdentity">Gender Identity</InputLabel>
						<Select
							native
							value={genderIdentity}
							// onChange={handleChange}
							name={"genderIdentity"}
							onChange={(e) => updateData(e.target.name, e.target.value)}
							inputProps={{
								name: "genderIdentity",
								id: "genderIdentity",
							}}
						>
							<option aria-label="None" value="" />
							{genderIdentityChoices.map((r) => (
								<option value={r} key={`control-${r}`}>
									{r}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!preferredPronoun || preferredPronoun == "")}
					>
						<InputLabel shrink htmlFor="preferredPronoun">
							Preferred Pronouns
						</InputLabel>
						<InputLabel htmlFor="preferredPronoun">Preferred Pronouns</InputLabel>
						<Select
							native
							value={preferredPronoun}
							// onChange={handleChange}
							name={"preferredPronoun"}
							onChange={(e) => updateData(e.target.name, e.target.value)}
							inputProps={{
								name: "preferredPronoun",
								id: "preferredPronoun",
							}}
						>
							<option aria-label="None" value="" />
							{preferredPronounChoices.map((r) => (
								<option value={r} key={`control-${r}`}>
									{r}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!race || race == "")}
					>
						<InputLabel shrink htmlFor="race">
							Race
						</InputLabel>
						<InputLabel htmlFor="gender">Race</InputLabel>
						<Select
							native
							value={race}
							// onChange={handleChange}
							name={"race"}
							onChange={(e) => updateData(e.target.name, e.target.value)}
							inputProps={{
								name: "race",
								id: "race",
							}}
						>
							<option aria-label="None" value="" />
							{raceChoices.map((r) => (
								<option value={r} key={`control-${r}`}>
									{r}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!ethnicity || ethnicity == "")}
					>
						<InputLabel shrink htmlFor="ethnicity">
							Ethnicity
						</InputLabel>
						<InputLabel htmlFor="ethnicity">Ethnicity</InputLabel>
						<Select
							native
							value={ethnicity}
							// onChange={handleChange}
							name={"ethnicity"}
							onChange={(e) => updateData(e.target.name, e.target.value)}
							inputProps={{
								name: "ethnicity",
								id: "ethnicity",
							}}
						>
							<option aria-label="None" value="" />
							{ethnicityChoices.map((r) => (
								<option value={r} key={`control-${r}`}>
									{r}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl fullWidth required error={error && (!phone || phone == "")}>
						<InputLabel shrink htmlFor="phone">
							Primary Phone #
						</InputLabel>
						{/* <InputLabel htmlFor="phone">Primary Phone #</InputLabel> */}
						<TextField
							id="phone"
							aria-describedby="phone-helper-text"
							name={"phone"}
							value={phone ? phone : ""}
							label={"Primary Phone #"}
							error={error && (!phone || phone == "")}
							onChange={(e) =>
								updateData(
									e.target.name,
									formatUSNumber(e.target.value ? e.target.value.toString() : e.target.value)
								)
							}
						/>
						<FormHelperText id="phone-helper-text">{/* Your email address. */}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						error={(error && (!email || email == "")) || errorFields.email}
						id="email"
						name="email"
						label="E-mail Address"
						fullWidth
						autoComplete="email"
						defaultValue={email}
						onChange={(e) => {
							var isValidEmail =
								/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
							console.log("is valid email", isValidEmail.test(e.target.value));
							updateData(e.target.name, e.target.value);
						}}
						onBlur={(e) => {
							var isValidEmail =
								/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
							if (!isValidEmail.test(e.target.value)) {
								setErrorFields({ ...errorFields, [e.target.name]: true });
							} else {
								setErrorFields({ ...errorFields, [e.target.name]: false });
							}
							console.log("errorFields", errorFields);
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Current Address
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Autocomplete
						onError={(e) => {
							console.log("autocomplete did error", e);
						}}
						// options={suggestedAddress && suggestedAddress.address ? selectedAddress ? suggestedAddress.address.map(f=>{return {value: `${f.address.streetNumber} ${f.address.streetName}`, inputValue: `${f.address.streetNumber} ${f.address.streetName}`}}).concat([{value: selectedAddress, inputValue: selectedAddress}]) : suggestedAddress.address.map(f=>{return {value: `${f.address.streetNumber} ${f.address.streetName}`, inputValue: `${f.address.streetNumber} ${f.address.streetName}` }}) : []}
						options={suggestedAddress.address}
						getOptionLabel={(option) => {
							console.log("option", option);
							return option && option.value && option.inputValue
								? `${option.freeformAddress}`
								: `${option.toString()}`;
						}}
						// defaultValue={address1}
						// renderOption={(option,state)=>{
						//   console.log('render option', option,state);
						// }}
						// value={s}

						value={address1 ? address1 : ""}
						// defaultValue={address1}
						// getOptionSelected={(option,value)=>selectedAddress ? true : false}
						onInputChange={(e, newValue) => {
							console.log("autocomplete on change", newValue, e, e ? e.target : "no target");
							setSelectedAddress(newValue);
							if (
								e &&
								e.target &&
								e.target.value &&
								e.target.value.length > 4 &&
								e.target.value.indexOf(" ") >= 0
							) {
								findAddress(e.target.value);
							} else {
								setSuggestedAddress({ address: [] });
							}
							if (e && e.target) {
								updateData(e.target.name, e.target.value);
								// updateData('city', newValue.address)
							}
						}}
						clearOnBlur={false}
						onChange={(event, option) => {
							console.log("on auto did change", option);
							if (option && option.streetNumber)
								data.setFormData({
									...data.formData,
									address1: `${option.streetNumber} ${option.streetName}`,
									city: option.localName,
									zip: option.postalCode,
								});
						}}
						renderOption={(option) => {
							console.log("render option", option);
							if (address1 !== `${option.streetNumber} ${option.streeName}`) {
								// updateData('address1', `${option.address.streetNumber} ${option.address.streetName}`)
								// data.setFormData({ ...data.formData, address1: selectedAddress, city: option.address.city, zip: option.address.postalCode });
							}
							// if (city !== option.address.localName) {
							//   updateData('city', option.address.localName)
							// }
							return <div>{`${option.freeformAddress}`}</div>;
						}}
						renderInput={(params) => {
							console.log("input params", params, suggestedAddress, selectedAddress);
							// if (selectedAddress && selectedAddress.indexOf(', ')) params.inputProps.value == selectedAddress.substring(0, selectedAddress.indexOf(', ')) &&
							return (
								//  ((selectedAddress && selectedAddress.indexOf(', ')) params.inputProps.value == selectedAddress.substring(0, selectedAddress.indexOf(', '))) &&
								<TextField
									{...params}
									id="address1"
									name="address1"
									label="Address line 1"
									fullWidth
									required
									error={error && (!address1 || address1 == "")}
									value={address1}
									// autoComplete="address-line1"
									// defaultValue={address1}
									// value={address1}
									// onChange={(e) => {
									//   console.log('address line 1', e.target.value);
									//   if (e.target.value && e.target.value.length > 4 && e.target.value.indexOf(' ') >= 0){
									//     findAddress(e.target.value);
									//   }
									//   updateData(e.target.name, e.target.value)
									// }
									// }
								/>
							);
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="address2"
						name="address2"
						label="Unit or Apartment #"
						fullWidth
						autoComplete="address-line2"
						defaultValue={address2}
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						error={error && (!city || city == "")}
						id="city"
						name="city"
						label="City"
						fullWidth
						autoComplete="address-level2"
						value={city ? city : ""}
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						error={error && (!zip || zip == "" || (zip && zip.length < 5))}
						id="zip"
						name="zip"
						label="Zip / Postal code"
						fullWidth
						autoComplete="billing postal-code"
						type={"number"}
						onBlur={() => {
							if (zip && zip != "" && zip.length < 5) {
								console.log("setting error fields");
								setErrorFields({ ...errorFields, zip: true });
							}
						}}
						// defaultValue={zip}
						value={zip ? zip : ""}
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Divider flexItem />
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Household Information
					</Typography>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!needInterpretation || needInterpretation == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="needInterpretation">
							Do you need interpretation services?
						</InputLabel>
						<InputLabel htmlFor="needInterpretation">
							Do you need interpretation services?
						</InputLabel>
						<Select
							native
							value={needInterpretation}
							onChange={(evt) => updateData("needInterpretation", evt.target.value)}
							inputProps={{
								name: "needInterpretation",
								id: "needInterpretation",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						disabled={!needInterpretation || needInterpretation != "yes"}
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required={needInterpretation && needInterpretation == "yes" ? true : false}
						error={
							error &&
							needInterpretation &&
							needInterpretation == "yes" &&
							(!needInterpretationLang || needInterpretationLang == "")
						}
					>
						<InputLabel shrink htmlFor="needInterpretationLang">
							If Yes, what language?
						</InputLabel>
						<InputLabel htmlFor="needInterpretationLang">If Yes, what language?</InputLabel>
						<Select
							native
							value={needInterpretationLang}
							// onChange={handleChange}
							name={"needInterpretationLang"}
							onChange={(e) => updateData(e.target.name, e.target.value)}
							inputProps={{
								name: "needInterpretationLang",
								id: "needInterpretationLang",
							}}
						>
							<option aria-label="None" value="" />
							{Languages.map((r) => (
								<option value={r} key={`control-${r}`}>
									{r}
								</option>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!typeOfHousing || typeOfHousing == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="type-of-housing">
							Type of Housing?
						</InputLabel>
						<InputLabel htmlFor="typeOfHousing">Type of Housing?</InputLabel>
						<Select
							native
							value={typeOfHousing}
							onChange={(evt) => updateData("typeOfHousing", evt.target.value)}
							inputProps={{
								name: "typeOfHousing",
								id: "typeOfHousing",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Apartment"}>Apartment</option>
							<option value={"Single Room Occupancy Unit (SRO)"}>
								Single Room Occupancy Unit (SRO)
							</option>
							<option value={"Room/Shared Housing"}>Room/Shared Housing</option>
							<option value={"House"}>House</option>
							<option value={"Mobile Home"}>Mobile Home</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!howManyInHousehold || howManyInHousehold == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-in-household">
							How many people are in your household? (include yourself)
						</InputLabel>
						<InputLabel htmlFor="howManyInHousehold">
							How many people are in your household? (include yourself)
						</InputLabel>
						<Select
							native
							value={howManyInHousehold}
							onChange={(evt) => updateData("howManyInHousehold", evt.target.value)}
							inputProps={{
								name: "howManyInHousehold",
								id: "how-many-in-household",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5"}>5</option>
							<option value={"6"}>6</option>
							<option value={"7"}>7</option>
							<option value={"8"}>8</option>
							<option value={"9+"}>9+</option>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={
							error && (!howManyChildrenInHouseholdUnder2 || howManyChildrenInHouseholdUnder2 == "")
						}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-children-household">
							How many children in the household under the age of 2 years old?
						</InputLabel>
						<InputLabel htmlFor="howManyChildrenInHousehold">
							How many children in the household under the age of 2 years old?
						</InputLabel>
						<Select
							native
							value={howManyChildrenInHouseholdUnder2}
							onChange={(evt) => updateData("howManyChildrenInHouseholdUnder2", evt.target.value)}
							inputProps={{
								name: "howManyChildrenInHouseholdUnder2",
								id: "how-many-children-household2",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"0"}>0</option>
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5+"}>5+</option>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!howManyChildrenInHousehold || howManyChildrenInHousehold == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-children-household">
							How many children in the household between the ages 3-17 years old?*
						</InputLabel>
						<InputLabel htmlFor="howManyChildrenInHousehold">
							How many children in the household between the ages 3-17 years old?*
						</InputLabel>
						<Select
							native
							value={howManyChildrenInHousehold}
							onChange={(evt) => updateData("howManyChildrenInHousehold", evt.target.value)}
							inputProps={{
								name: "howManyChildrenInHousehold",
								id: "how-many-children-household",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"0"}>0</option>
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5+"}>5+</option>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!howMany18to24InHousehold || howMany18to24InHousehold == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-18to24-household">
							How many people are between the ages of 18-24 yrs.?
						</InputLabel>
						<InputLabel htmlFor="howMany18to24InHousehold">
							How many people are between the ages of 18-24 yrs.?
						</InputLabel>
						<Select
							native
							value={howMany18to24InHousehold}
							onChange={(evt) => updateData("howMany18to24InHousehold", evt.target.value)}
							inputProps={{
								name: "howMany18to24InHousehold",
								id: "how-many-18to24-household",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"0"}>0</option>
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5+"}>5+</option>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!howMany25to54InHousehold || howMany25to54InHousehold == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-25to54-household">
							How many people are between the ages of 25-61 yrs.?
						</InputLabel>
						<InputLabel htmlFor="howMany25to54InHousehold">
							How many people are between the ages of 25-61 yrs.?
						</InputLabel>
						<Select
							native
							value={howMany25to54InHousehold}
							onChange={(evt) => updateData("howMany25to54InHousehold", evt.target.value)}
							inputProps={{
								name: "howMany25to54InHousehold",
								id: "how-many-25to54-household",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"0"}>0</option>
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5+"}>5+</option>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!howManySeniors || howManySeniors == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="how-many-seniors">
							How many people are 62 yrs. and older?
						</InputLabel>
						<InputLabel htmlFor="howManySeniors">How many people are 62 yrs. and older?</InputLabel>
						<Select
							native
							value={howManySeniors}
							onChange={(evt) => updateData("howManySeniors", evt.target.value)}
							inputProps={{
								name: "howManySeniors",
								id: "how-many-seniors",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"0"}>0</option>
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5+"}>5+</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!headOfHousehold25 || headOfHousehold25 == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="headOfHousehold62">
							Is the Head of Household 62 years or older?
						</InputLabel>
						<InputLabel htmlFor="headOfHousehold62">
							Is the Head of Household 62 years or older?
						</InputLabel>
						<Select
							native
							value={headOfHousehold62}
							onChange={(evt) => updateData("headOfHousehold62", evt.target.value)}
							inputProps={{
								name: "headOfHousehold62",
								id: "headOfHousehold62",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!headOfHousehold25 || headOfHousehold25 == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="headOfHousehold25">
							Is the Head of Household under 25 yrs old?
						</InputLabel>
						<InputLabel htmlFor="headOfHousehold25">
							Is the Head of Household under 25 yrs old?
						</InputLabel>
						<Select
							native
							value={headOfHousehold25}
							onChange={(evt) => updateData("headOfHousehold25", evt.target.value)}
							inputProps={{
								name: "headOfHousehold25",
								id: "headOfHousehold25",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!anyoneInHouseholdVeteran || anyoneInHouseholdVeteran == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="household-veteran">
							Are you or any other adult in the household a veteran?
						</InputLabel>
						<InputLabel htmlFor="anyoneInHouseholdVeteran">
							Are you or any other adult in the household a veteran?
						</InputLabel>
						<Select
							native
							value={anyoneInHouseholdVeteran}
							onChange={(evt) => updateData("anyoneInHouseholdVeteran", evt.target.value)}
							inputProps={{
								name: "anyoneInHouseholdVeteran",
								id: "household-veteran",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{/* <Grid item xs={12} sm={6}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
            error={error && (!householdPregnant || householdPregnant == "")}
          >
            <InputLabel shrink htmlFor="householdPregnant">
              Is anyone in your household currently pregnant?
            </InputLabel>
            <InputLabel htmlFor="householdPregnant">
              Is anyone in your household currently pregnant?
            </InputLabel>
            <Select
              native
              required
              value={householdPregnant}
              onChange={(evt) =>
                updateData("householdPregnant", evt.target.value)
              }
              inputProps={{
                name: "householdPregnant",
                id: "householdPregnant",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid> */}

				{/* <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="section8Voucher">
              Do you currently have a Section 8 Voucher?
            </InputLabel>
            <InputLabel htmlFor="section8Voucher">
              Do you currently have a Section 8 Voucher?
            </InputLabel>
            <Select
              native
              value={section8Voucher}
              onChange={(evt) =>
                updateData("section8Voucher", evt.target.value)
              }
              inputProps={{
                name: "section8Voucher",
                id: "section8Voucher",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"yes"}>Yes</option>
              <option value={"no"}>No</option>
            </Select>
          </FormControl>
        </Grid> */}
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Employment Status
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						required
						error={error && (!employed || employed == "")}
						fullWidth
						className={"MuiFormControl-marginNormal"}
					>
						<InputLabel shrink htmlFor="employed">
							Are you employed?
						</InputLabel>
						<InputLabel htmlFor="employed">Are you employed?</InputLabel>
						<Select
							native
							value={employed}
							onChange={(evt) => updateData("employed", evt.target.value)}
							inputProps={{
								name: "employed",
								id: "employed",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
						<InputLabel shrink htmlFor="occupation">
							What is your occupation?
						</InputLabel>
						<InputLabel htmlFor="occupation">What is your occupation?</InputLabel>
						<Select
							disabled={!employed || employed == "" || employed == "no"}
							native
							value={occupation}
							onChange={(evt) => updateData("occupation", evt.target.value)}
							inputProps={{
								name: "occupation",
								id: "occupation",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Farmworker/Agriculture/Day Laborer"}>
								Farmworker/Agriculture/Day Laborer
							</option>
							<option value={"Hospitality (Hotel, Restaurant, Retail)"}>
								Hospitality (Hotel, Restaurant, Retail)
							</option>
							<option value={"Childcare/Education"}>Childcare/Education</option>
							<option value={"Self-Employed"}>Self-Employed</option>
							<option value={"Other"}>Other</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
						<InputLabel shrink htmlFor="employment">
							Type of Employment
						</InputLabel>
						<InputLabel htmlFor="employment">Type of Employment</InputLabel>
						<Select
							disabled={!employed || employed == "" || employed == "no"}
							native
							value={employment}
							onChange={(evt) => updateData("employment", evt.target.value)}
							inputProps={{
								name: "employment",
								id: "employment",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Full-time"}>Full-time</option>
							<option value={"Part-time"}>Part-time</option>
							<option value={"Hourly"}>Hourly</option>
							<option value={"Seasonal"}>Seasonal</option>
							<option value={"Informal"}>Informal</option>
							<option value={"Other"}>Other</option>
						</Select>
					</FormControl>
				</Grid>
				{/* <Grid item xs={12} sm={6}>
          <FormControl fullWidth className={"MuiFormControl-marginNormal"}>
            <InputLabel shrink htmlFor="type-of-assistance">
              What type of assistance are you seeking today? (MUST HAVE
              DOCUMENTATION)
            </InputLabel>
            <InputLabel htmlFor="typeOfAssistance">
              What type of assistance are you seeking today? (MUST HAVE
              DOCUMENTATION)
            </InputLabel>
            <Select
              native
              value={typeOfAssistance}
              onChange={(evt) =>
                updateData("typeOfAssistance", evt.target.value)
              }
              inputProps={{
                name: "typeOfAssistance",
                id: "type-of-assistance",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Full-time"}>De-time</option>
              <option value={"Part-time"}>Part-time</option>
            </Select>
          </FormControl>
        </Grid> */}
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Back Rent and Utilities
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
						{currentMonthlyRent && currentMonthlyRent != "" ? null : (
							<InputLabel style={{ paddingLeft: 15 }} htmlFor="currentMonthlyRent">
								What is your current monthly rent?
							</InputLabel>
						)}
						<TextField
							// disabled={!toaBackRent}
							id="currentMonthlyRent"
							name="currentMonthlyRent"
							label={"What is your current monthly rent?"}
							fullWidth
							type={"number"}
							value={currentMonthlyRent ? currentMonthlyRent : ""}
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
							}}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
						{backRentAmount && backRentAmount != "" ? null : (
							<InputLabel style={{ paddingLeft: 15 }} htmlFor="backRentAmount">
								How much total rent do you currently owe?
							</InputLabel>
						)}
						<TextField
							// disabled={!toaBackRent}
							id="backRentAmount"
							name="backRentAmount"
							label={"How much total rent do you currently owe?"}
							fullWidth
							type={"number"}
							value={backRentAmount ? backRentAmount : ""}
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
							}}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						required
						error={error && (!backRentOwed || backRentOwed == "")}
					>
						<InputLabel shrink htmlFor="backRentOwed">
							Is back rent owed for a unit you are currently or previously lived in?
						</InputLabel>
						<InputLabel htmlFor="backRentOwed">
							Is back rent owed for a unit you are currently or previously lived in?
						</InputLabel>
						<Select
							native
							value={backRentOwed}
							onChange={(evt) => {
								updateData("backRentOwed", evt.target.value);
							}}
							inputProps={{
								name: "backRentOwed",
								id: "backRentOwed",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"} style={{ marginTop: 0 }}>
						<InputLabel shrink htmlFor="backRentMonths">
							Number of months behind on rent?
						</InputLabel>
						<TextField
							// disabled={!toaBackRent}
							id="backRentMonths"
							name="backRentMonths"
							label="Number of months behind on rent?"
							fullWidth
							type={"number"}
							value={backRentMonths ? backRentMonths : ""}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl
						fullWidth
						className={"MuiFormControl-marginNormal"}
						style={{ marginTop: 0 }}
						required
						error={error && (!unpaidUtilities || unpaidUtilities == "")}
					>
						<InputLabel shrink htmlFor="unpaidUtilities">
							Do you have unpaid utilities, e.g., PG&E, water, internet?
						</InputLabel>
						<InputLabel htmlFor="unpaidUtilities">
							Do you have unpaid utilities, e.g., PG&E, water, internet?
						</InputLabel>
						<Select
							native
							error={error && (!unpaidUtilities || unpaidUtilities == "")}
							value={unpaidUtilities}
							onChange={(evt) => updateData("unpaidUtilities", evt.target.value)}
							inputProps={{
								name: "unpaidUtilities",
								id: "unpaidUtilities",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
						{backUtilityAmount && backUtilityAmount != "" ? null : (
							<InputLabel style={{ paddingLeft: 15 }} htmlFor="backUtilityAmount">
								How much do you currently owe in back utility payments?
							</InputLabel>
						)}
						<TextField
							// disabled={!toaBackRent}
							id="backUtilityAmount"
							name="backUtilityAmount"
							label={"How much do you currently owe in back utility payments?"}
							fullWidth
							type={"number"}
							value={backUtilityAmount ? backUtilityAmount : ""}
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
							}}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"} style={{ marginTop: 0 }}>
						<InputLabel shrink htmlFor="backUtilityMonths">
							Number of months behind on utilities?
						</InputLabel>
						<TextField
							// disabled={!toaBackRent}
							id="backUtilityMonths"
							name="backUtilityMonths"
							label="Number of months behind on utilities?"
							fullWidth
							type={"number"}
							value={backUtilityMonths ? backUtilityMonths : ""}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>

				{/* <Grid
            item
            xs={12}
            sm={6}
          >
            <FormControl
              fullWidth
              className={"MuiFormControl-marginNormal"}
              required
              error={
                error && (!liveInRentControlledUnit || liveInRentControlledUnit == "")
              }
            >
              <InputLabel shrink htmlFor="liveInRentControlledUnit">
                Do you live in a rent-controlled unit?
              </InputLabel>
              <InputLabel htmlFor="liveInRentControlledUnit">
                Do you live in a rent-controlled unit?
              </InputLabel>
              <Select
                native
                error={error && (!liveInRentControlledUnit || liveInRentControlledUnit == "")}
                value={liveInRentControlledUnit}
                onChange={(evt) =>
                  updateData("liveInRentControlledUnit", evt.target.value)
                }
                inputProps={{
                  name: "liveInRentControlledUnit",
                  id: "liveInRentControlledUnit",
                }}
              >
                <option aria-label="None" value="" />
                <option value={"yes"}>Yes</option>
                <option value={"no"}>No</option>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormControl
              fullWidth
              className={"MuiFormControl-marginNormal"}
              required
              error={
                error && (!livedFor5YearsMore || livedFor5YearsMore == "")
              }
            >
              <InputLabel shrink htmlFor="livedFor5YearsMore">
                Have you lived there for 5 years or more?
              </InputLabel>
              <InputLabel htmlFor="livedFor5YearsMore">
                Have you lived there for 5 years or more?
              </InputLabel>
              <Select
                native
                value={livedFor5YearsMore}
                onChange={(evt) =>
                  updateData("livedFor5YearsMore", evt.target.value)
                }
                inputProps={{
                  name: "livedFor5YearsMore",
                  id: "livedFor5YearsMore",
                }}
              >
                <option aria-label="None" value="" />
                <option value={"yes"}>Yes</option>
                <option value={"no"}>No</option>
              </Select>
            </FormControl>
          </Grid> */}

				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
						Landlord Information
					</Typography>
					<Typography variant="h6" gutterBottom style={{ color: "#666", fontSize: "1rem" }}>
						Please list name and address where you make your rent checks payable to.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="llName"
						name="llName"
						value={llName ? llName : ""}
						label="Landlord (First & Last) or Company Name"
						fullWidth
						autoComplete="llName"
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						// error={error && (!landlordaddress1 || landlordaddress1 == "")}
						id="landlordaddress1"
						name="landlordaddress1"
						value={landlordaddress1 ? landlordaddress1 : ""}
						label="Landlord Address line 1"
						fullWidth
						autoComplete="address-line1"
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="landlordaddress2"
						name="landlordaddress2"
						value={landlordaddress2 ? landlordaddress2 : ""}
						label="Landlord Address line 2"
						fullWidth
						autoComplete="address-line2"
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						// error={error && (!landlordcity || landlordcity == "")}
						// id="landlordcity"
						name="landlordcity"
						value={landlordcity ? landlordcity : ""}
						label="Landlord City"
						fullWidth
						autoComplete="address-level2"
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="landlordstate"
						name="landlordstate"
						// error={error && (!landlordstate || landlordstate == "")}
						value={landlordstate ? landlordstate : ""}
						label="Landlord State/Province/Region"
						onChange={(e) => updateData(e.target.name, e.target.value)}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						// error={error && (!landlordzip || landlordzip == "")}
						id="landlordzip"
						name="landlordzip"
						value={landlordzip ? landlordzip : ""}
						label="Landlord Zip / Postal code"
						fullWidth
						autoComplete="billing postal-code"
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						fullWidth
						// error={error && (!landlordPhone || landlordPhone == "")}
					>
						<InputLabel htmlFor="landlord-primary-phone">Landlord Primary Phone #</InputLabel>
						<Input
							id="landlord-primary-phone"
							aria-describedby="landlord-phone-helper-text"
							value={landlordPhone ? landlordPhone : ""}
							name={"landlordPhone"}
							onChange={(e) =>
								updateData(
									e.target.name,
									formatUSNumber(e.target.value ? e.target.value.toString() : e.target.value)
								)
							}
						/>
						<FormHelperText id="landlord-phone-helper-text">
							{/* Your email address. */}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						// error={error && (!landlordEmail || landlordEmail == "")}
						id="landlordEmail"
						name="landlordEmail"
						value={landlordEmail ? landlordEmail : ""}
						label="Landlord E-mail"
						fullWidth
						onChange={(e) => updateData(e.target.name, e.target.value)}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
