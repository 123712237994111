import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import DescriptionIcon from "@material-ui/icons/Description";

import NapaDocs from './Napa_Document Verification List.pdf';

const imgStyle = {
  height: 65,
  // maxWidth: "80%",
  objectFit: "cover",
  display: "block",
  margin: "0 auto",
  marginTop: 10,
  marginBottom: 10,
};

export default function Intro(formData) {
  const { whatCity } = formData.formData;


  return (
    <React.Fragment>
      {/* <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Emeryville} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Hayward} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={KOH} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Oakland} />
        </Grid>
      </Grid> */}
      {/* <div>
        <img style={imgStyle} src={cityImage()} />
      </div> */}
      <Typography
        style={{ color: "#666", textDecoration: "underline", marginTop: 1 }}
      >
      You will need the following things:
      </Typography>
      <Grid container spacing={3} onClick={()=>window.open(NapaDocs, '_blank')}>
        <Grid item xs={12} style={{cursor: 'pointer'}}>
          <List>
            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="One form of personal identification​"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Verification of where you live​" />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Proof of loss of income or financial hardship" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="Verification of rent or utilities owed​"

              />
            </ListItem>
            {/* <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="For those self-employed: tax records, income statements, or other evidence showing loss of income​"

              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="Other items will be considered and if you have trouble producing these documents, someone will work with you to help you provide the necessary information.​"

              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="Please try to gather these additional documents.​"
                secondary={'Click here to download document checklist'}

              />
            </ListItem> */}
            {/* <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="Need help?"
                secondary={'Once you submit your application we will contact you. If you have trouble completing this online application please call us at ____.'}
              />
            </ListItem> */}
            {/* <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="W9 from Landlord / Property Owner"
                secondary={
                  <span>
                    {
                      "If you cannot submit with application, you will need to submit before final determination can be made.  Click "
                    }
                    <a
                      href={"https://www.irs.gov/pub/irs-pdf/fw9.pdf"}
                      target={"_blank"}
                    >
                      {"here"}
                    </a>
                    {" to download W-9 form to provide to your landlord."}
                  </span>
                }
              />
            </ListItem> */}
          </List>
        </Grid>
      </Grid>      
      <Typography
        gutterBottom
        style={{ color: "#666", textAlign: 'left' }}
      >Please click any of the items above to see examples of the kinds of documents that will be accepted.  If you have trouble providing documents, a self-declaration may be accepted.  Someone will work with you to help you provide the necessary information.​<br/><br/>
You will <span style={{textDecoration: 'underline', fontWeight: 600}}>not</span> be asked about citizenship, nor will you be required to show proof of citizenship.</Typography>

      <Typography
        variant="h6"
        gutterBottom
        style={{ color: "#666", paddingTop: 25, textAlign: "left" }}
      >Need Help?</Typography>

      <Typography        
        gutterBottom
        style={{ color: "#666", paddingTop: 5, textAlign: "left" }}
      >Once you have submitted your application – we will contact you.</Typography>

      <Typography
        variant="h5"
        gutterBottom
        style={{ color: "#666", paddingTop: 20, textAlign: "center" }}
      >
        Please note that applications do not save progress and must be submitted
        once complete.
      </Typography>
      {/* <Typography
        variant="h6"
        gutterBottom
        style={{ color: "#666", paddingTop: 50 }}
      >
        If you have gathered the necessary documents, click the NEXT button to begin.
      </Typography> */}
    </React.Fragment>
  );
}
