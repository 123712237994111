import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircleIcon from "@material-ui/icons/PlayCircleFilled";

import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DescriptionIcon from "@material-ui/icons/Description";

import Napa from "./images/napa.png";
import Napa2 from "./images/napa2.jpg";
import Napa3 from "./images/napa3.png";
import KOH from "./images/koh.png";
import Hayward from "./images/hayward.jpg";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import AMI from "./images/ami.png";

const imgStyle = {
	height: 135,
	// maxWidth: "80%",
	objectFit: "cover",
	display: "block",
	margin: "0 auto",
	marginTop: 10,
	marginBottom: 10,
};

var formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
});

const ami50 = [45700, 52200, 58750, 65250, 70500, 75700, 80950, 86150];

const ami30 = [27450, 31350, 35250, 39150, 42300, 45450, 48550, 51700];

const ami80 = [76760, 87700, 98650, 109600, 118400, 127150, 135950, 144700];

export default function SelectCity(data) {
	const [open, setOpen] = React.useState(false);

	const [renterLandlord, setRenterLandlord] = React.useState({
		type: "",
		income50ami: false,
		deedRestricted: false,
		buttonClicked: false,
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const updateData = (field, value) => {
		data.setFormData({ ...data.formData, ready: true });
	};

	const hCutoff =
		new Date().toUTCString() >= new Date(Date.UTC(2020, 4, 20, 1, 0, 0)).toUTCString();

	console.log("data", data);

	const { whatCity, error } = data.formData;

	const { setActiveStep } = data;

	// if (open) {
	//   setTimeout(()=>{
	//     if (document.getElementsByClassName('MuiDialog-root').length > 0) {
	//     document.getElementsByClassName('MuiDialog-root')[0].id='testing1234';
	//     }
	// },1000)
	// }

	return (
		<React.Fragment>
			<Dialog
				open={open && whatCity == "Napa"}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				onBackdropClick={async () => {
					setRenterLandlord({
						type: "",
						income50ami: false,
						deedRestricted: false,
						buttonClicked: false,
					});
					handleClose();
				}}
			>
				{/* <DialogTitle id="alert-dialog-title">{""}</DialogTitle> */}
				<DialogContent>
					<DialogContentText id="alert-dialog-description"></DialogContentText>

					{renterLandlord.buttonClicked && renterLandlord.type == "landlord" && (
						<Grid container spacing={1} style={{ paddingTop: "2em", paddingBottom: "2em" }}>
							<Grid item xs={12}>
								{renterLandlord.deedRestricted && (
									<div style={{ paddingBottom: "3em", textAlign: "center", fontSize: "1.5em" }}>
										San Francisco's Emergency Rental Assistance Program prioritizes properties that
										are 100% restricted affordable housing.
										<br />
										<br /> To apply, please email:{" "}
										<a
											target="_blank"
											href={
												"mailto:HousingAssistance@oaklandca.gov?subject=ERAP for Affordable Housing"
											}
										>
											HousingAssistance@oaklandca.gov
										</a>{" "}
										to request a Property Profile Application Form. Please note: "ERAP for
										Affordable Housing" in subject line. This Application Form will include
										instructions.​
									</div>
								)}
								{!renterLandlord.deedRestricted && (
									<div style={{ paddingBottom: "3em", textAlign: "center", fontSize: "1.5em" }}>
										San Francisco's Emergency Rental Assistance Program prioritizes properties that
										are 100% restricted affordable housing. However, the State of CA is partnering
										with San Francisco to offer emergency rental assistance to landlords with
										properties in Oakland whose tenants earn 80% Area Median Income or less. <br />
										<br /> Please visit{" "}
										<a target="_blank" href={"http://housingiskey.com"}>
											www.housingiskey.com
										</a>
										, and click on CA COVID-19 Rent Relief for more information.
									</div>
								)}
							</Grid>
						</Grid>
					)}
					{!renterLandlord.buttonClicked && renterLandlord.type == "landlord" && (
						<Grid container spacing={1} style={{ paddingTop: "2em", paddingBottom: "2em" }}>
							<Grid item xs={12}>
								<div style={{ paddingBottom: "3em", textAlign: "center", fontSize: "1.5em" }}>
									The State of CA is partnering with San Francisco to offer emergency rental
									assistance to landlords with properties in San Francisco whose tenants earn 80%
									Area Median Income or less, including those that are restricted to 100% affordable
									housing.
									<br />
									<br />
									Please visit{" "}
									<a target="_blank" href={"http://housingiskey.com"}>
										www.housingiskey.com
									</a>
									, and click on CA COVID-19 Rent Relief for more information.
								</div>
							</Grid>
						</Grid>
					)}

					{renterLandlord.buttonClicked &&
						renterLandlord.type == "renter" &&
						!renterLandlord.income50ami && (
							<Grid container spacing={1} style={{ paddingTop: "2em", paddingBottom: "2em" }}>
								<Grid item xs={12}>
									<div style={{ paddingBottom: "3em", textAlign: "center", fontSize: "1.5em" }}>
										San Francisco's Emergency Rental Assistance Program prioritizes households below
										30% of Area Median Income. However, the State of CA is partnering with Oakland
										to offer emergency rental assistance for households that earn between 30-80% of
										Area Median Income. <br />
										<br /> Please visit{" "}
										<a target="_blank" href="http://housingiskey.com">
											www.housingiskey.com
										</a>
										, and click on CA COVID-19 Rent Relief for more information. ​
									</div>
								</Grid>
							</Grid>
						)}
					{!renterLandlord.buttonClicked && renterLandlord.type == "renter" && (
						<Grid container spacing={1} style={{ paddingBottom: "2em" }} className="translate">
							<Grid item xs={12}>
								<div style={{ paddingBottom: "0.5em", textAlign: "center", fontSize: "1.5em" }}>
									Is your TOTAL CURRENT household income less than 30% Area Media Income?
								</div>
								<Grid container spacing={1} style={{ paddingBottom: 10 }}>
									<Grid item xs={5} style={{ textAlign: "right" }}>
										People in Household
									</Grid>
									<Grid item xs={2}></Grid>
									<Grid item xs={5}>
										30% of Median
									</Grid>
									{ami30.map((ami, i) => [
										<Grid key={`ami-${ami}-${i}-1`} item xs={5} style={{ textAlign: "right" }}>
											{i + 1}
										</Grid>,
										<Grid key={`ami-${ami}-${i}-2`} item xs={2}></Grid>,
										<Grid key={`ami-${ami}-${i}-3`} item xs={5}>
											{formatter.format(ami).replace(".00", "")}
										</Grid>,
									])}
								</Grid>
							</Grid>
							<Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
								<div
									aria-label="No"
									className="oaklandButton"
									onClick={() =>
										setRenterLandlord({
											...renterLandlord,
											buttonClicked: true,
											income50ami: false,
										})
									}
								>
									<strong>No</strong>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} style={{ textAlign: "left" }}>
								<div
									aria-label="Yes"
									className="oaklandButton"
									onClick={() => {
										updateData();
										handleClose();
									}}
								>
									<strong>Yes</strong>
								</div>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={async () => {
							// updateData("whatCity", "");
							setRenterLandlord({
								type: "",
								income50ami: false,
								deedRestricted: false,
								buttonClicked: false,
							});
							handleClose();
						}}
						color="primary"
					>
						Cancel
					</Button>
					{/* <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button> */}
				</DialogActions>
			</Dialog>

			<Grid container spacing={1}>
				<Grid item xs={12} sm={4}>
					<img style={imgStyle} src={Napa} />
				</Grid>

				<Grid item xs={12} sm={4}>
					<img style={imgStyle} src={Napa3} />
				</Grid>

				<Grid item xs={12} sm={4}>
					<img style={imgStyle} src={Napa2} />
				</Grid>
			</Grid>

			<Typography style={{ color: "#666", marginTop: 15, textAlign: "left", fontWeight: "bold" }}>
				Community Update: The Keep Napa County Housed application is temporarily closed as of
				October 31, 2023, while partner agencies seek additional funding to prevent homelessness and
				keep people housed in Napa County.
				<br />
				<br />
				We thank our community members for their interest and participation in the program, and we
				hope to reopen the application as soon as additional funding is secured.
				<br />
				<br /> Please continue to visit{" "}
				<a href="https://keepnapacountyhoused.org/">https://keepnapacountyhoused.org/</a> for
				updates. For urgent tenant and landlord issues, please contact the following:
				<br />
			</Typography>

			<List className="introList">
				<ListItem className="multiLevel">
					<ListItemIcon>{/* <FiberManualRecordIcon /> */}</ListItemIcon>
					<ListItemText
						primary={
							<b>
								Bay Area Legal Aid: For those experiencing landlord harassment or discrimination.
								Appointment required.
							</b>
						}
						secondary={
							<List>
								<ListItem>
									<ListItemIcon className={"smallIcon"}>
										<RadioButtonUnchecked />
									</ListItemIcon>
									<ListItemText
										primary={
											<div>
												Phone Number: (707) 259-0579
												<br />
												Legal Advice Line: (800) 551-5554
											</div>
										}
									/>
								</ListItem>
							</List>
						}
					/>
				</ListItem>

				<ListItem className="multiLevel">
					<ListItemIcon>{/* <FiberManualRecordIcon /> */}</ListItemIcon>
					<ListItemText
						primary={
							<b>
								Fair Housing Napa Valley: Provides free, objective, and confidential services to
								both tenants and landlords to help them understand their rights and responsibilities
								under state and local laws and ordinances.
							</b>
						}
						secondary={
							<List>
								<ListItem>
									<ListItemIcon className={"smallIcon"}>
										<RadioButtonUnchecked />
									</ListItemIcon>
									<ListItemText primary={<div>Phone Number: (707) 224-9720</div>} />
								</ListItem>
							</List>
						}
					/>
				</ListItem>

				<ListItem className="multiLevel">
					<ListItemIcon>{/* <FiberManualRecordIcon /> */}</ListItemIcon>
					<ListItemText
						primary={
							<b>
								Season of Sharing: Provides temporary financial assistance to individuals and
								families in crisis to pay for housing and emergency needs that cannot be met through
								other resources.
							</b>
						}
						secondary={
							<List>
								<ListItem>
									<ListItemIcon className={"smallIcon"}>
										<RadioButtonUnchecked />
									</ListItemIcon>
									<ListItemText
										primary={
											<div>
												Phone Number: (707) 299-1890
												<br />
												Email: seasonofsharing@countyofnapa.org
											</div>
										}
									/>
								</ListItem>
							</List>
						}
					/>
				</ListItem>

				<ListItem className="multiLevel">
					<ListItemIcon>{/* <FiberManualRecordIcon /> */}</ListItemIcon>
					<ListItemText
						primary={
							<b>
								Monarch Justice Center: For anyone who has experienced or been impacted by domestic
								violence, sexual assault, stalking, child abuse, elder abuse or human trafficking.
							</b>
						}
						secondary={
							<List>
								<ListItem>
									<ListItemIcon className={"smallIcon"}>
										<RadioButtonUnchecked />
									</ListItemIcon>
									<ListItemText
										primary={
											<div>
												Phone Number: (707) 527-3355
												<br />
												Address: 1546 1st Street, Napa, CA 94559
											</div>
										}
									/>
								</ListItem>
							</List>
						}
					/>
				</ListItem>

				<ListItem className="multiLevel">
					<ListItemIcon>{/* <FiberManualRecordIcon /> */}</ListItemIcon>
					<ListItemText
						primary={
							<b>
								Mentis: Bilingual affordable mental health services to people of every age and
								income level in Napa County.
							</b>
						}
						secondary={
							<List>
								<ListItem>
									<ListItemIcon className={"smallIcon"}>
										<RadioButtonUnchecked />
									</ListItemIcon>
									<ListItemText
										primary={
											<div>
												Phone Number: (707) 255-0966
												<br />
												Address: 709 Franklin Street, Napa, California 94559
												<br />
												Email: info@mentisnapa.org
											</div>
										}
									/>
								</ListItem>
							</List>
						}
					/>
				</ListItem>

				<ListItem className="multiLevel">
					<ListItemIcon>{/* <FiberManualRecordIcon /> */}</ListItemIcon>
					<ListItemText
						primary={<b>Napa County Health & Human Services: For CalFresh, CalWorks & Medical.</b>}
						secondary={
							<List>
								<ListItem>
									<ListItemIcon className={"smallIcon"}>
										<RadioButtonUnchecked />
									</ListItemIcon>
									<ListItemText
										primary={
											<div>
												Phone Number: (707) 253-4279
												<br />
												Address: 2751 Napa Valley Corporate Drive, Napa, CA 94558
											</div>
										}
									/>
								</ListItem>
							</List>
						}
					/>
				</ListItem>

				<ListItem className="multiLevel">
					<ListItemIcon>{/* <FiberManualRecordIcon /> */}</ListItemIcon>
					<ListItemText
						primary={<b>On The Move: Napa Valley Recovery Center.</b>}
						secondary={
							<List>
								<ListItem>
									<ListItemIcon className={"smallIcon"}>
										<RadioButtonUnchecked />
									</ListItemIcon>
									<ListItemText
										primary={
											<div>
												Phone Number: (707) 277-2721
												<br />
												Address: 780 Lincoln Avenue, Napa, CA 94558
												<br />
												Email: efa@onthemovebayarea.org
											</div>
										}
									/>
								</ListItem>
							</List>
						}
					/>
				</ListItem>

				<ListItem className="multiLevel">
					<ListItemIcon>{/* <FiberManualRecordIcon /> */}</ListItemIcon>
					<ListItemText
						primary={<b>UpValley Family Centers: Family Resource Center.</b>}
						secondary={
							<List>
								<ListItem>
									<ListItemIcon className={"smallIcon"}>
										<RadioButtonUnchecked />
									</ListItemIcon>
									<ListItemText
										primary={
											<div>
												Phone Number: (707) 965-5010
												<br />
												Address (Calistoga): 1500 Cedar Street, Calistoga, CA 94515
												<br />
												Address (St. Helena): 1400 Spring Street, St. Helena, CA 94574
											</div>
										}
									/>
								</ListItem>
							</List>
						}
					/>
				</ListItem>
			</List>

			<Grid container spacing={1}>
				<Grid item xs={12} style={{ position: "absolute", left: "-100000px" }}>
					<FormControl fullWidth className={"MuiFormControl-marginNormal"}>
						<InputLabel shrink htmlFor="what-city">
							What city do you live in?
						</InputLabel>
						<InputLabel htmlFor="what-city">What city do you live in?</InputLabel>
						<Select
							native
							value={whatCity}
							onChange={(evt) => {
								console.log(evt.target);
								if (evt.target.value !== "Oakland") setOpen(true);
								if (
									evt.target.value == "Hayward2" ||
									(evt.target.value == "Emeryville" && new Date() >= new Date("06/16/2020"))
								) {
									// updateData("whatCity", null);
									updateData("whatCity", evt.target.value);
									document.getElementById(
										"message"
									).textContent = `Our apologies but the application portal has been closed due to the rent relief funding having been fully expended.`;
								} else {
									document.getElementById("message").textContent = "";
									updateData("whatCity", evt.target.value);
									if (evt.target.value == "Oakland")
										document.location.href = "https://sfevictprev.com/";
									if (evt.target.value == "Fremont")
										document.location.href = "https://sfevictprev.com/";
								}
							}}
							inputProps={{
								name: "whatCity",
								id: "what-city",
							}}
						>
							<option aria-label="None" value={""} />
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
