import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Slider from "@material-ui/core/Slider";

var formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
});

export default function Questionarre(formData, setFormData) {
	const updateData = (field, value) => {
		const data = formData;
		data.setFormData({ ...data.formData, [field]: value });
	};

	const {
		householdMonthlyGross,
		amiCalculator,
		expSuddenLossIncome,
		livingWithoutLease,
		expHomelessness,
		everHadNameOnLease,
		error,
		whatCity,
		evictionNotice,
		evictionNoticeDate,
		policeInvolvement,
		householdMajorChange,
		anyoneInHouseholdDisabled,
		dischargeHospitalMHSub,
		ineligibleAssistance,
		unemployed90Days,
		homelessLast2Years,
		landlordFiledPapers,
		householdPriorMonthlyGross,
		howManyInHousehold,
		specificOrganization,
		expAbuse,
		anyonePregnant,
	} = formData.formData;
	// console.log("questionare data", formData);

	const limits2019 = [44150, 50450, 56750, 63050, 68100, 73150, 78200, 83250, 88300];
	const limits502020 = [45700, 52200, 58750, 65250, 70500, 75700, 80950, 86150];
	const emyLimits2019 = [78200, 83950, 100550, 111700, 120650];

	const limits2020 = [];

	const houseHoldQty =
		howManyInHousehold && howManyInHousehold != ""
			? howManyInHousehold == "9+"
				? 8
				: parseInt(howManyInHousehold)
			: 0;

	if (householdMonthlyGross)
		console.log(
			"householdQty is",
			houseHoldQty,
			limits2019[parseInt(houseHoldQty) - 1],
			householdMonthlyGross * 12,
			limits2019[parseInt(houseHoldQty) - 1]
		);

	if (whatCity && whatCity == "Emeryville" && amiCalculator > emyLimits2019.length)
		setTimeout(() => {
			updateData("amiCalculator", 1);
		}, 200);

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
				These questions are used to better understand your current housing needs
			</Typography>

			<Grid container spacing={3}>
				{/* #1 */}
				<Grid item xs={12}>
					<FormControl required fullWidth className={"MuiFormControl-marginNormal"} required>
						<InputLabel shrink htmlFor="evictionNotice">
							Have you received an eviction notice or has someone indicated in any way that you must
							leave your current housing?
						</InputLabel>
						<InputLabel htmlFor="evictionNotice">
							Have you received an eviction notice or has someone indicated in any way that you must
							leave your current housing?
						</InputLabel>
						<Select
							native
							required
							error={error && (!evictionNotice || evictionNotice == "")}
							value={evictionNotice}
							onChange={(evt) => updateData("evictionNotice", evt.target.value)}
							inputProps={{
								name: "evictionNotice",
								id: "evictionNotice",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #1a */}
				<Grid item xs={12}>
					{/* <InputLabel htmlFor="evictionNoticeLength" style={{marginTop:-10}}>
              Have you been given specific date by which you have to leave?
            </InputLabel> */}
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							margin="normal"
							id="evictionNoticeDate"
							label="Have you been given specific date by which you have to leave?"
							fullWidth
							name={"evictionNoticeDate"}
							value={evictionNoticeDate}
							required={false}
							// error={error && (!evictionNoticeDate || evictionNoticeDate == "")}
							// onChange={handleDateChange}
							// defaultValue={dob}
							onChange={(date, value) => updateData("evictionNoticeDate", value)}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
						/>
					</MuiPickersUtilsProvider>
				</Grid>

				{/* #2 */}
				<Grid item xs={12}>
					<FormControl required fullWidth className={"MuiFormControl-marginNormal longText"}>
						<InputLabel shrink htmlFor="livingWithoutLease">
							Are you currently living or staying in someone else's home or apartment where you
							don't have a lease?
						</InputLabel>
						<InputLabel htmlFor="livingWithoutLease">
							Are you currently living or staying in someone else's home or apartment where you
							don't have a lease?
						</InputLabel>
						<Select
							required
							error={error && (!livingWithoutLease || livingWithoutLease == "")}
							native
							value={livingWithoutLease}
							onChange={(evt) => updateData("livingWithoutLease", evt.target.value)}
							inputProps={{
								name: "livingWithoutLease",
								id: "livingWithoutLease",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #3 */}

				{/* #4 */}
				<Grid item xs={12} sm={12}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
						error={error && (!anyonePregnant || anyonePregnant == "")}
					>
						<InputLabel shrink htmlFor="anyonePregnant">
							Is anyone in your household currently pregnant?
						</InputLabel>
						<InputLabel htmlFor="anyonePregnant">
							Is anyone in your household currently pregnant?
						</InputLabel>
						<Select
							native
							required
							value={anyonePregnant}
							onChange={(evt) => updateData("anyonePregnant", evt.target.value)}
							inputProps={{
								name: "anyonePregnant",
								id: "anyonePregnant",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #5 */}
				<Grid item xs={12} className={"longText"}>
					<FormControl required fullWidth className={"MuiFormControl-marginNormal"}>
						{householdMonthlyGross && householdMonthlyGross != "" ? null : (
							<InputLabel style={{ paddingLeft: 15 }} htmlFor="householdMonthlyGross">
								What is your HOUSEHOLD'S current monthly income?
							</InputLabel>
						)}
						<TextField
							required
							error={error && (!householdMonthlyGross || householdMonthlyGross == "")}
							id="householdMonthlyGross"
							name="householdMonthlyGross"
							label={<span>What is your HOUSEHOLD'S current monthly income?</span>}
							type={"number"}
							fullWidth
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
							}}
							defaultValue={householdMonthlyGross}
							onChange={(e) => updateData(e.target.name, e.target.value)}
						/>
					</FormControl>
				</Grid>

				{householdMonthlyGross && (
					<Grid item xs={12}>
						<Typography variant="h6" style={{ color: "#666" }}>
							Your current annual income is: ${householdMonthlyGross * 12}
						</Typography>
					</Grid>
				)}
				{householdMonthlyGross && (
					<Grid item xs={12}>
						<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
							You are{" "}
							{parseFloat(
								((householdMonthlyGross * 12) / limits2019[parseInt(houseHoldQty) - 1]) * 100
							).toFixed(2)}{" "}
							percent of the area median income
						</Typography>
					</Grid>
				)}

				{/* <Grid item xs={12} className={"longText"}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal"}
          >
              { householdPriorMonthlyGross && householdPriorMonthlyGross != "" ? null : <InputLabel style={{paddingLeft: 15}} htmlFor="householdPriorMonthlyGross">What was your HOUSEHOLDS monthly income prior to the Covid 19 Pandemic (March 2020)?</InputLabel> }
            <TextField
              required
              error={
                error && (!householdPriorMonthlyGross || householdPriorMonthlyGross == "")
              }
              id="householdPriorMonthlyGross"
              name="householdPriorMonthlyGross"
              label={'What was your HOUSEHOLDS monthly income prior to the Covid 19 Pandemic (March 2020)?'
              }
              type={'number'}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              defaultValue={householdMonthlyGross}
              onChange={(e) => updateData(e.target.name, e.target.value)}
            />
          </FormControl>
        </Grid> */}

				{householdPriorMonthlyGross && (
					<Grid item xs={12}>
						<Typography variant="h6" style={{ color: "#666" }}>
							Your current annual income is: ${householdPriorMonthlyGross * 12}
						</Typography>
					</Grid>
				)}
				{householdPriorMonthlyGross && (
					<Grid item xs={12}>
						<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
							You are{" "}
							{parseFloat(
								((householdPriorMonthlyGross * 12) / limits2019[parseInt(houseHoldQty) - 1]) * 100
							).toFixed(2)}{" "}
							percent of the area median income
						</Typography>
					</Grid>
				)}

				{/* <Grid item xs={12} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
            <Typography variant="h6" gutterBottom>
              {whatCity == "Emeryville"
                ? "Emeryville 2019 100% AMI Income Limit Calculator"
                : "2020 50% of AMI Calculator"}
            </Typography>
            <Slider
              style={{ paddingLeft: 5, paddingRight: 5 }}
              defaultValue={1}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={
                whatCity == "Emeryville"
                  ? emyLimits2019.length
                  : limits502020.length
              }
              value={amiCalculator2}
              onChange={(evt, val) => updateData("amiCalculator2", val)}
              marks={
                whatCity == "Emeryville"
                  ? emyLimits2019.map((limit, i) => {
                      // console.log("mapping emyLimits", limit, i);
                      return {
                        value: i + 1,
                        label: window.innerWidth < 1024 ? i+1 : i + 1 + (i + 1 == 1 ? " person" : " people"),
                      };
                    })
                  : limits502020.map((limit, i) => {
                      // console.log("mapping normal limits");
                      return {
                        value: i + 1,
                        label: window.innerWidth < 1024 ? i+1 : i + 1 + (i + 1 == 1 ? " person" : " people"),
                      };
                    })
              }
            />
          </Grid>


          <Grid
            item
            container
            xs={12}
            spacing={1}
            style={{
              marginTop: -10,
              paddingTop: 0,
              textDecoration: "bold",
            }}
          >
            <div style={{ fontWeight: 600, width: "100%" }}>
              {amiCalculator2 && amiCalculator2 > 0 ? (
                <div>
                  {amiCalculator2}{" "}
                  {amiCalculator2 <= 1 ? " person 50% limit: " : " people 50% limit: "}
                  {formatter
                    .format(
                      whatCity == "Emeryville"
                        ? emyLimits2019[amiCalculator2 - 1]
                        : limits502020[amiCalculator2 - 1]
                    )
                    .toString()
                    .replace(".00", "")}
                </div>
              ) : null}
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl
              required
              error={error && (!makeLess50AMI || makeLess50AMI == "")}
              fullWidth
              className={"MuiFormControl-marginNormal longText"}
            >
              <InputLabel shrink htmlFor="makeLess50AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                  <span>Does your household’s total income equal less than 50% AMI based on the amounts listed above?</span>
                )}
              </InputLabel>
              <InputLabel htmlFor="makeLess50AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                  <span>Does your household’s total income equal less than 50% AMI based on the amounts listed above?</span>
                )}
              </InputLabel>
              <Select
                native
                value={makeLess50AMI}
                onChange={(evt) =>
                  updateData("makeLess50AMI", evt.target.value)
                }
                inputProps={{
                  name: "makeLess50AMI",
                  id: "makeLess50AMI",
                }}
              >
                <option aria-label="None" value="" p={0} />
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </Select>
            </FormControl>
          </Grid>


        {whatCity != "Hayward" && (
          <Grid item xs={12} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
            <Typography variant="h6" gutterBottom>
              {whatCity == "Emeryville"
                ? "Emeryville 2019 100% AMI Income Limit Calculator"
                : "2020 30% of AMI Calculator"}
            </Typography>
            <Slider
              style={{ paddingLeft: 5, paddingRight: 5 }}
              defaultValue={1}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={
                whatCity == "Emeryville"
                  ? emyLimits2019.length
                  : limits2019.length
              }
              value={amiCalculator}
              onChange={(evt, val) => updateData("amiCalculator", val)}
              marks={
                whatCity == "Emeryville"
                  ? emyLimits2019.map((limit, i) => {
                      // console.log("mapping emyLimits", limit, i);
                      return {
                        value: i + 1,
                        label: window.innerWidth < 1024 ? i+1 : i + 1 + (i + 1 == 1 ? " person" : " people"),
                      };
                    })
                  : limits2019.map((limit, i) => {
                      // console.log("mapping normal limits");
                      return {
                        value: i + 1,
                        label: window.innerWidth < 1024 ? i+1 : i + 1 + (i + 1 == 1 ? " person" : " people"),
                      };
                    })
              }
            />
          </Grid>
        )}
        {whatCity != "Hayward" && (
          <Grid
            item
            container
            xs={12}
            spacing={1}
            style={{
              marginTop: -10,
              paddingTop: 0,
              textDecoration: "bold",
            }}
          >
            <div style={{ fontWeight: 600, width: "100%" }}>
              {amiCalculator && amiCalculator > 0 ? (
                <div>
                  {amiCalculator}{" "}
                  {amiCalculator <= 1 ? " person 30% limit: " : " people 30% limit: "}
                  {formatter
                    .format(
                      whatCity == "Emeryville"
                        ? emyLimits2019[amiCalculator - 1]
                        : limits2019[amiCalculator - 1]
                    )
                    .toString()
                    .replace(".00", "")}
                </div>
              ) : null}
            </div>
          </Grid>
        )}
        {whatCity != "Hayward" && (

          <Grid item xs={12} sm={12}>
            <FormControl
              required
              error={error && (!makeLess30AMI || makeLess30AMI == "")}
              fullWidth
              className={"MuiFormControl-marginNormal longText"}
            >
              <InputLabel shrink htmlFor="makeLess30AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                  <span>Does your household’s total income equal less than 30% AMI based on the amounts listed above?</span>
                )}
              </InputLabel>
              <InputLabel htmlFor="makeLess30AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                    <span>Does your household’s total income equal less than 30% AMI based on the amounts listed above?</span>
                )}
              </InputLabel>
              <Select
                native
                value={makeLess30AMI}
                onChange={(evt) =>
                  updateData("makeLess30AMI", evt.target.value)
                }
                inputProps={{
                  name: "makeLess30AMI",
                  id: "makeLess30AMI",
                }}
              >
                <option aria-label="None" value="" p={0} />
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </Select>
            </FormControl>
          </Grid>
        )}

          <Grid item xs={12} sm={12}>
            <FormControl
              required
              error={error && (!priorToCovid30AMI || priorToCovid30AMI == "")}
              fullWidth
              className={"MuiFormControl-marginNormal longText"}
            >
              <InputLabel shrink htmlFor="priorToCovid30AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                  <span>Prior to the COVID-19 pandemic, did your household’s total income equal less than 30% AMI based on the amounts above?</span>
                )}
              </InputLabel>
              <InputLabel htmlFor="priorToCovid30AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                    <span>Prior to the COVID-19 pandemic, did your household’s total income equal less than 30% AMI based on the amounts above?</span>
                )}
              </InputLabel>
              <Select
                native
                value={makeLess30AMI}
                onChange={(evt) =>
                  updateData("priorToCovid30AMI", evt.target.value)
                }
                inputProps={{
                  name: "priorToCovid30AMI",
                  id: "priorToCovid30AMI",
                }}
              >
                <option aria-label="None" value="" p={0} />
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </Select>
            </FormControl>
          </Grid> */}

				{/* #10 */}
				<Grid item xs={12} sm={12}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
						error={error && (!expSuddenLossIncome || expSuddenLossIncome == "")}
					>
						<InputLabel shrink htmlFor="expSuddenLossIncome">
							Has your household had a sudden income change which is impacting your ability to stay
							housed?
						</InputLabel>
						<InputLabel htmlFor="expSuddenLossIncome">
							Has your household had a sudden income change which is impacting your ability to stay
							housed?
						</InputLabel>
						<Select
							native
							required
							value={expSuddenLossIncome}
							onChange={(evt) => updateData("expSuddenLossIncome", evt.target.value)}
							inputProps={{
								name: "expSuddenLossIncome",
								id: "expSuddenLossIncome",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={12}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
						required
						error={error && (!unemployed90Days || unemployed90Days == "")}
					>
						<InputLabel shrink htmlFor="unemployed90Days">
							Has any adult in your household been unemployed for more than 90 days?
						</InputLabel>
						<InputLabel htmlFor="unemployed90Days">
							Has any adult in your household been unemployed for more than 90 days?
						</InputLabel>
						<Select
							native
							required
							value={unemployed90Days}
							onChange={(evt) => updateData("unemployed90Days", evt.target.value)}
							inputProps={{
								name: "unemployed90Days",
								id: "unemployed90Days",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #11 */}
				<Grid item xs={12}>
					<FormControl fullWidth required className={"MuiFormControl-marginNormal longText"}>
						<InputLabel shrink htmlFor="expHomelessness">
							Have you ever been homeless in the past? (meaning that you slept outside, your car, or
							another place not for people to sleep, or in shelter)​
						</InputLabel>
						<InputLabel htmlFor="expHomelessness">
							Have you ever been homeless in the past? (meaning that you slept outside, your car, or
							another place not for people to sleep, or in shelter)​
						</InputLabel>
						<Select
							required
							error={error && (!expHomelessness || expHomelessness == "")}
							native
							value={expHomelessness}
							onChange={(evt) => updateData("expHomelessness", evt.target.value)}
							inputProps={{
								name: "expHomelessness",
								id: "expHomelessness",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={12}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
						required
						error={error && (!homelessLast2Years || homelessLast2Years == "")}
					>
						<InputLabel shrink htmlFor="homelessLast2Years">
							Have you been homeless in the last 2 years?
						</InputLabel>
						<InputLabel htmlFor="homelessLast2Years">
							Have you been homeless in the last 2 years?
						</InputLabel>
						<Select
							native
							required
							value={homelessLast2Years}
							onChange={(evt) => updateData("homelessLast2Years", evt.target.value)}
							inputProps={{
								name: "homelessLast2Years",
								id: "homelessLast2Years",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #12 */}
				<Grid item xs={12}>
					<FormControl required fullWidth className={"MuiFormControl-marginNormal longText"}>
						<InputLabel shrink htmlFor="everHadNameOnLease">
							Have you ever had your name on a lease?
						</InputLabel>
						<InputLabel htmlFor="everHadNameOnLease">
							Have you ever had your name on a lease?
						</InputLabel>
						<Select
							required
							error={error && (!everHadNameOnLease || everHadNameOnLease == "")}
							native
							value={everHadNameOnLease}
							onChange={(evt) => updateData("everHadNameOnLease", evt.target.value)}
							inputProps={{
								name: "liveInSubsidized",
								id: "liveInSubsidized",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={12}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
						required
						error={error && (!landlordFiledPapers || landlordFiledPapers == "")}
					>
						<InputLabel shrink htmlFor="landlordFiledPapers">
							Has your landlord ever filed papers against you in court which resulted in you having
							to leave your home?
						</InputLabel>
						<InputLabel htmlFor="landlordFiledPapers">
							Has your landlord ever filed papers against you in court which resulted in you having
							to leave your home?
						</InputLabel>
						<Select
							native
							required
							value={landlordFiledPapers}
							onChange={(evt) => updateData("landlordFiledPapers", evt.target.value)}
							inputProps={{
								name: "landlordFiledPapers",
								id: "landlordFiledPapers",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
						</Select>
					</FormControl>
				</Grid>

				<Typography variant="h6" gutterBottom style={{ color: "#666" }}>
					The following questions will not in any way affect your eligibility for assistance. We are
					asking them to determine if you may be eligible for additional services.
					<br />
					We believe everyone has the right to safe and stable housing.
				</Typography>

				{/* #13 */}

				<Grid item xs={12} sm={12}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
						error={error && (!policeInvolvement || policeInvolvement == "")}
					>
						<InputLabel shrink htmlFor="policeInvolvement">
							Have you or any adult in your household been arrested or spent any time in jail or
							prison in the last two years?
						</InputLabel>
						<InputLabel htmlFor="policeInvolvement">
							Have you or any adult in your household been arrested or spent any time in jail or
							prison in the last two years?
						</InputLabel>
						<Select
							native
							required
							value={policeInvolvement}
							onChange={(evt) => updateData("policeInvolvement", evt.target.value)}
							inputProps={{
								name: "policeInvolvement",
								id: "policeInvolvement",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
							<option aria-label="I'd prefer not to say" value="I'd prefer not to say" p={0}>
								I'd prefer not to say
							</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #14 */}
				<Grid item xs={12} sm={12}>
					<FormControl
						required
						error={error && (!anyoneInHouseholdDisabled || anyoneInHouseholdDisabled == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="household-disabled">
							Do you or anyone in your household have a disabling condition which impacts your
							ability to secure/maintain housing?
						</InputLabel>
						<InputLabel htmlFor="anyoneInHouseholdDisabled">
							Do you or anyone in your household have a disabling condition which impacts your
							ability to secure/maintain housing?
						</InputLabel>
						<Select
							native
							value={anyoneInHouseholdDisabled}
							onChange={(evt) => updateData("anyoneInHouseholdDisabled", evt.target.value)}
							inputProps={{
								name: "anyoneInHouseholdDisabled",
								id: "household-disabled",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
							<option aria-label="I'd prefer not to say" value="I'd prefer not to say" p={0}>
								I'd prefer not to say
							</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #15 */}
				<Grid item xs={12} sm={12}>
					<FormControl
						required
						error={error && (!dischargeHospitalMHSub || dischargeHospitalMHSub == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="dischargeHospitalMHSub">
							Have you or any adult in your household been discharged from a hospital, mental health
							facility or substance abuse treatment facility in the last year?
						</InputLabel>
						<InputLabel htmlFor="dischargeHospitalMHSub">
							Have you or any adult in your household been discharged from a hospital, mental health
							facility or substance abuse treatment facility in the last year?
						</InputLabel>
						<Select
							native
							value={dischargeHospitalMHSub}
							onChange={(evt) => updateData("dischargeHospitalMHSub", evt.target.value)}
							inputProps={{
								name: "dischargeHospitalMHSub",
								id: "household-disabled",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
							<option aria-label="I'd prefer not to say" value="I'd prefer not to say" p={0}>
								I'd prefer not to say
							</option>
						</Select>
					</FormControl>
				</Grid>

				{/* #15 */}
				<Grid item xs={12} sm={12}>
					<FormControl
						required
						error={error && (!ineligibleAssistance || ineligibleAssistance == "")}
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
					>
						<InputLabel shrink htmlFor="ineligibleAssistance">
							Is your household ineligible for certain federal subisides or assistance, such as
							unemployment assistance?
						</InputLabel>
						<InputLabel htmlFor="ineligibleAssistance">
							Is your household ineligible for certain federal subisides or assistance, such as
							unemployment assistance?
						</InputLabel>
						<Select
							native
							value={ineligibleAssistance}
							onChange={(evt) => updateData("ineligibleAssistance", evt.target.value)}
							inputProps={{
								name: "ineligibleAssistance",
								id: "household-disabled",
							}}
						>
							<option aria-label="None" value="" />
							<option value={"yes"}>Yes</option>
							<option value={"no"}>No</option>
							<option aria-label="I'd prefer not to say" value="I'd prefer not to say" p={0}>
								I'd prefer not to say
							</option>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={12}>
					<FormControl
						required
						fullWidth
						className={"MuiFormControl-marginNormal longText"}
						error={error && (!policeInvolvement || policeInvolvement == "")}
					>
						<InputLabel shrink htmlFor="expAbuse">
							In the past two years have you or anyone in your household experienced emotional,
							physical or financial abuse?
						</InputLabel>
						<InputLabel htmlFor="expAbuse">
							In the past two years have you or anyone in your household experienced emotional,
							physical or financial abuse?
						</InputLabel>
						<Select
							native
							required
							value={expAbuse}
							onChange={(evt) => updateData("expAbuse", evt.target.value)}
							inputProps={{
								name: "expAbuse",
								id: "expAbuse",
							}}
						>
							<option aria-label="None" value="" p={0} />
							<option value={"Yes"}>Yes</option>
							<option value={"No"}>No</option>
							<option aria-label="I'd prefer not to say" value="I'd prefer not to say" p={0}>
								I'd prefer not to say
							</option>
						</Select>
					</FormControl>
				</Grid>

				{/* <Grid item xs={12} sm={12}>
          <FormControl
            required
            error={
              error &&
              (!specificOrganization || specificOrganization == "")
            }
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="specificOrganization">
              If you prefer to work with a specific organization to determine whether you qualify for assistance, please select one from the list below. 
            </InputLabel>
            <InputLabel htmlFor="specificOrganization">
              If you prefer to work with a specific organization to determine whether you qualify for assistance, please select one from the list below. 
            </InputLabel>
            <Select
              native
              value={specificOrganization}
              onChange={(evt) =>
                updateData("specificOrganization", evt.target.value)
              }
              inputProps={{
                name: "specificOrganization",
                id: "specificOrganization",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Bay Area Community Services"}>Bay Area Community Services</option>
              <option value={"Building Opportunities for Self-Sufficiency (BOSS)"}>Building Opportunities for Self-Sufficiency (BOSS)</option>
              <option value={"Catholic Charities of the East Bay"}>Catholic Charities of the East Bay</option>
              <option value={"Centro Legal de la Raza"}>Centro Legal de la Raza</option>
              <option value={"East Bay Asian Local Development Corporation (EBALDC)"}>East Bay Asian Local Development Corporation (EBALDC)</option>
              <option value={"Eviction Defense Center ​"}>Eviction Defense Center</option>
              <option value={"Safe Passages"}>Safe Passages</option>
              <option value={"No Preference"}>No Preference</option>
            </Select>
          </FormControl>
        </Grid> */}
			</Grid>
		</React.Fragment>
	);
}
